import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Banner from 'components/Banner'
import Secondary from 'components/Secondary'
import ReviewsList from 'components/Reviews'
import LinkDuplicate from 'components/LinkDuplicate'
import Button from 'components/Button'

const Reviews = props => {
	const { pageContext } = props
	const { reviews } = pageContext
	return (
		<Layout>
			<SEO
				title="Отзывы клиентов по базам для рассылок — «Парсик»"
				description="Что говорят клиенты после проведения рассылок. Все отзывы настоящие со скриншотами переписок и записями разговоров"
			/>
			<Banner
				title="Отзывы клиентов"
				description="<strong>Настоящие отзывы</strong> со&nbsp;скриншотами переписок и&nbsp;записями разговоров"
				image={{ name: 'home', width: 506, height: 392 }}
			>
				<Button isLink to="/contacts.html" title="Наши контакты" theme="light" size="lg">
					Написать отзыв
				</Button>
			</Banner>
			<Sector>
				<Row className="text-center mb-lg">
					<Col lgOffset={1} lg={10}>
						<p className="fs-h4">
							Клиенты делают рассылки по{' '}
							<LinkDuplicate to="/dbs.html#list" title="Базы компаний для рассылок">
								базам компаний
							</LinkDuplicate>{' '}
							и&nbsp;любезно делятся результатом. Мы&nbsp;будем очень рады и&nbsp;вашему отзыву!
						</p>
						<Secondary tag="p">Личные данные скрыты по&nbsp;соображениям конфиденциальности</Secondary>
					</Col>
				</Row>
				<ReviewsList reviews={reviews} limit={25} />
			</Sector>
			<Sector color="gray" title="Оставьте ваш отзыв" titleCentered>
				<Row className="text-center mb-lg">
					<Col lgOffset={1} lg={10}>
						<p>
							Мы&nbsp;собираем отзывы через все возможные каналы связи: по&nbsp;электронной почте, через whatsapp,
							телеграм, по&nbsp;телефону. Обратная связь очень важна для понимания потребностей клиентов
							и&nbsp;повышения качества услуг
						</p>
						<p>Обещаем не&nbsp;раскрывать ваши личные данные</p>
						<p className="mt-lg">
							<Button isLink to="/contacts.html" title="Наши контакты" theme="primary">
								Написать отзыв
							</Button>
						</p>
					</Col>
				</Row>
			</Sector>
		</Layout>
	)
}

export default Reviews
